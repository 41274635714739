import React from "react"
import SSLBanner from "./ssl-banner"
import Call from "../call"

const SSLComponent = () => (
  <>
    <SSLBanner />
    <section className="c-ssl-body">
      <section className="wrapper">
        <section className="c-ssl-body__container">
          <h1 className="c-ssl-body__title">Certificados SSL</h1>

          <p className="c-ssl-body__description">
            SSL es la tecnología de seguridad para establecer un enlace cifrado
            entre un servidor y un cliente. Este enlace asegura que todos los
            datos transferidos entre el servidor y el cliente permanezcan
            seguros y no puedan ser intervenidos por terceros.
          </p>
          <p className="c-ssl-body__description">
            En el caso de un servidor web el protocolo se conoce como https. En
            la actualidad es indispensable servir el contenido web mediante
            https para garantizar a los visitantes que toda la información que
            introduzcan se transmite de una manera segura.
          </p>
          <p className="c-ssl-body__description">
            También se utilizan los certificados SSL para otro tipo de servicios
            como pueden ser servidor de correo, servidor FTP, etc. Garantizando
            que las conexiones cliente-servidor se realizan de manera segura en
            todo momento.
          </p>
          <p className="c-ssl-body__description">
            Existen unas entidades certificadoras que emiten estos certificados
            y son reconocidos por los navegadores como legítimos, esto permite
            que se verifique que la conexión se está realizando al dominio que
            visitamos realmente.
          </p>
          <p className="c-ssl-body__description">
            Hay tres tipos de certificados dependiendo del tipo de validación y
            tres tipos más dependiendo de si es para un solo dominio, varios
            dominios o varios dominios y subdominios, por eso en RackMarkt los
            hemos clasificado de forma fácil para usted.
          </p>
        </section>
      </section>
    </section>

    <section className="c-ssl-body --lightgreen">
      <section className="wrapper">
        <section className="c-ssl-body__container">
          <h1 className="c-ssl-body__title">Tipos de SSL y Diferencias</h1>

          <p className="c-ssl-body__description">
            Existen varias entidades certificadoras SSL que ofrecen distintos
            tipos de certificados en función de las necesidades del cliente.
          </p>
          <p className="c-ssl-body__description">
            Los certificados más comunes son los llamados DV (Domain
            Validation), son certificados SSL que se emiten verificando que el
            dominio que protegen pertenece a quien solicitó el certificado.
          </p>
          <p className="c-ssl-body__description">
            Dentro de los certificados DV, podemos optar por asegurar 1 dominio
            solamente (Certificado simple), certificar varios dominios bajo un
            mismo certificado (Certificado Multi-Dominio), certificar 1 dominio
            y cualquier subdominio del mismo (Certificado Wildcard).
          </p>
          <p className="c-ssl-body__description">
            El siguiente tipo de certificado es el conocido como OV
            (Organization validation), este certificado además de verificar la
            posesión del dominio, verifica que se han hecho unas comprobaciones
            sobre quién está detrás del certificado. Estos certificados no se
            emiten inmediatamente, ya que requieren varios pasos de
            certificación adicionales y pueden tardar entre 3-5 días.
          </p>


          <section className="c-ssl-body__icons-container">
            <div className="c-ssl-body__icon">
              <img src="https://via.placeholder.com/100" alt="Icono"/>
              <span>Organization</span>
              <span>Authentication</span>
            </div>
            <div className="c-ssl-body__icon">
              <img src="https://via.placeholder.com/100" alt="Icono"/>
              <span>Locality</span>
              <span>Presence</span>
            </div>
            <div className="c-ssl-body__icon">
              <img src="https://via.placeholder.com/100" alt="Icono"/>
              <span>Telephone</span>
              <span>Verification</span>
            </div>
            <div className="c-ssl-body__icon">
              <img src="https://via.placeholder.com/100" alt="Icono"/>
              <span>Domain</span>
              <span>Verification</span>
            </div>
            <div className="c-ssl-body__icon">
              <img src="https://via.placeholder.com/100" alt="Icono"/>
              <span>Final</span>
              <span>Verification Call</span>
            </div>
          </section>


          <p className="c-ssl-body__description">
            Un paso más y con el nivel máximo de validación están los
            certificados EV (Extended Validation), este tipo de certificado
            realiza una verificación completa de la entidad, teniendo que firmar
            contratos, demostrar la existencia de la organización, verificación
            física de la dirección, verificación telefónica, etc. Este tipo de
            certificados puede llevar entre 7 y 15 días para poder emitirlos. Su
            coste es bastante elevado pero refleja una validación y autenticidad
            total tanto en la conexión como sobre la entidad que está detrás.
          </p>

          <section className="c-ssl-body__icons-container">
          <div className="c-ssl-body__icon">
            <img src="https://via.placeholder.com/100" alt="Icono"/>
            <span>Organization</span>
            <span>Authentication</span>
          </div>
          <div className="c-ssl-body__icon">
            <img src="https://via.placeholder.com/100" alt="Icono"/>
            <span>Enrollment</span>
            <span>Form</span>
          </div>
          <div className="c-ssl-body__icon">
            <img src="https://via.placeholder.com/100" alt="Icono"/>
            <span>Operational</span>
            <span>Existence</span>
          </div>
          <div className="c-ssl-body__icon">
            <img src="https://via.placeholder.com/100" alt="Icono"/>
            <span>Pyshical</span>
            <span>Address</span>
          </div>
          <div className="c-ssl-body__icon">
            <img src="https://via.placeholder.com/100" alt="Icono"/>
            <span>Telephone</span>
            <span>Verification</span>
          </div>
          <div className="c-ssl-body__icon">
            <img src="https://via.placeholder.com/100" alt="Icono"/>
            <span>Domain</span>
            <span>Authentication</span>
          </div>
          <div className="c-ssl-body__icon">
            <img src="https://via.placeholder.com/100" alt="Icono"/>
            <span>Final</span>
            <span>Verification Call</span>
          </div>
        </section>

          <p className="c-ssl-body__description">
            A efectos de cifrado, todos los tipos de certificado tienen la misma
            protección. La diferencia es el nivel de verificación sobre quien
            adquiere el certificado.
          </p>
        </section>
      </section>
    </section>
    <Call/>
  </>
)

export default SSLComponent
