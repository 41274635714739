import React from "react"

const SSLBanner = () => (
  <section className="c-banner-ssl">
    <section className="wrapper">
      <section className="c-banner-ssl__container">
        <div className="c-banner-ssl__info">
          <h5 className="c-banner-ssl__title">Asegure las comunicaciones en sus conexiones</h5>
          
        </div>
      </section>
    </section>
  </section>
)

export default SSLBanner
