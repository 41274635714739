import React from "react"

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import SSLComponent from "../components/ssl-certificate";

const SSLCertificate = () => (
  <Layout>
    <SEO title="Certificados SSL" />
    <SSLComponent />
  </Layout>
)

export default SSLCertificate